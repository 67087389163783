import React, { useState } from "react";
import "./styles.css";

const SnippetsDataTable = ({ snippets }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter templates based on the search term using regex
  const filteredTemplates = Object.entries(snippets).filter(
    ([title, content]) => {
      const regex = new RegExp(searchTerm, "i");
      return regex.test(content.prefix) || regex.test(content.description);
    }
  );

  return (
    <div className="containers">
      <input
        type="text"
        placeholder="Search by prefix or description..."
        className="search-bar"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="grid-container">
        {filteredTemplates.length > 0 ? (
          filteredTemplates.map(([title, content]) => (
            <div key={title} className="template-card">
              <h2>{title}</h2>
              <div className="code-div">
                <p>
                  <strong>Prefix:</strong> {content.prefix}
                </p>

                {/* <pre className="code-block">{content.body.join("\n")}</pre> */}
                <pre className="code-block">
                  {Array.isArray(content.body)
                    ? content.body.join(" ")
                    : content.body}
                </pre>
                <p>
                  <strong>Description:</strong> {content.description}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No templates found.</p>
        )}
      </div>
    </div>
  );
};

export default SnippetsDataTable;
