import React from "react";
import "./Header.css";
import { useTheme } from "../../context/ThemeContext";
import light from "../../assets/snippets/light.png";
import dark from "../../assets/snippets/dark.png";
import logo from "../../assets/snippets/logo.png";

const Header = () => {
  const { theme, toggleTheme } = useTheme();

  console.log(theme);
  return (
    <>
      <div className="main_header">
        <div className="branding_img">
          <img src={logo} alt="" srcset="" />
          <h5 style={{ color: "#fff" }}>DevSnip Pro Developer Doc</h5>
        </div>
        <button
          className="toggle"
          onClick={toggleTheme}
          style={{
            background: `${theme === "dark" ? "#4f4f4f" : "#c4c0c0"}`,
          }}
        >
          <div
            className="btn_toggle"
            style={{
              marginLeft: `${theme === "light" ? "31px" : "3px"}`,
              background: `${theme === "dark" ? "black" : "#ffffff"}`,
            }}
          ></div>
        </button>
        {/* <button className="theme_button" onClick={toggleTheme}>
          {theme === "light" ? (
            <img src={dark} alt="" srcset="" />
          ) : (
            <img src={light} alt="" srcset="" />
          )}{" "}
        </button> */}
      </div>
    </>
  );
};

export default Header;
