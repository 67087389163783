import React from "react";
import "./style.css";
import NavHub from "../../components/index/NavHub";

import TitleDoc from "../../components/index/TitleDoc";

const WhatIsDevSnip = () => {
  return (
    <>
      <div className="main_container_doc">
        <div className="contain_docs">
          <TitleDoc title="What is DevSnip Pro ?" />

          <p className="description_doc">
            In the context of a tool like DevSnip Pro, snippets refer to short
            pieces of reusable code that can be quickly inserted into your
            project to save time and improve productivity. These snippets can be
            custom (user-defined) or preloaded (provided by the tool or the
            community). Let's break down the two concepts you've mentioned:
            Custom Snippets and Preloaded Multi-Language Snippet Support.
          </p>
          <p className="description_doc">
            DevSnip Pro is a code snippet extension designed for Visual Studio
            Code, aimed at enhancing the coding experience for developers. Here
            are the key features and details about DevSnip Pro:
          </p>
          <section>
            <h2 className="header_doc" id="features">
              1. Features
            </h2>
            <p>
              <strong>How it Helps:</strong>
              <ul>
                <li className="description_doc">
                  <strong> All-in-One Code Snippets:</strong>
                  DevSnip Pro provides a comprehensive collection of code
                  snippets that can help streamline coding tasks and improve
                  efficiency.
                </li>
                <li className="description_doc">
                  <strong>Debugger Tool:</strong> It includes a vibrant debugger
                  tool that assists developers in identifying and fixing issues
                  in their code more effectively.
                </li>
                <li className="description_doc">
                  <strong>User-Friendly Interface:</strong> sssThe extension is
                  designed to be intuitive, making it accessible for both new
                  and experienced developers.
                </li>
              </ul>
            </p>

            <h2 className="header_doc" id="target_audience">
              2. Target Audience
            </h2>
            <p>
              <ul>
                <li className="description_doc">
                  This tool is particularly beneficial for software developers
                  looking to enhance their productivity with quick access to
                  commonly used code snippets and debugging capabilities.
                </li>
                <li className="description_doc">
                  Overall, DevSnip Pro is a valuable resource for developers
                  seeking to optimize their coding workflow within Visual Studio
                  Code.
                </li>
              </ul>
            </p>
          </section>
        </div>

        <div className="index_doc">
          <NavHub />
          <ul>
            <li>
              <a href="#features">1. Features</a>
            </li>
            <li>
              <a href="#target_audience">2. Target Audience</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhatIsDevSnip;
