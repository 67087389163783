import React from "react";
import Main from "./components/Main";
import MainDev from "./components/DevSnip/Main";
import Document from "./Documents/Document";

/* ---------------------------- Document routers ---------------------------- */
import { WhyDevSnip } from "./Documents/Data/WhyDevSnip";

/* -------------------------------------------------------------------------- */
import { BrowserRouter, Routes, Route } from "react-router";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/devSnipMain" element={<MainDev />} />
        <Route path="/dev-snip-pro/document/en/*" element={<Document />} />
      </Routes>
    </>
  );
};

export default App;
